import '../css/App.scss';
import SetupForm from '../components/SetupForm';

function Setup() {
  return (
    <div className="App">
      <div>
        <h1>AJ'S<br/>SLIDES</h1>
        <SetupForm />
      </div>
    </div>
  );
}

export default Setup;